import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Collapse, Divider, Input, Button, Modal } from 'antd';
import { FaArchive } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import { LabelInput } from '../../../../../../components';
import { InformationContainer } from '../../../../../viability/pages/SubscribeFlow/FinalReport/components/InformationContainer';
import { PrincipalActivites } from './models/fakeData';
import { useHistory } from 'react-router-dom';
import api from '../../../../../../services/api';
import { useProtocol } from '../../../../../../hooks/protocol';
import { useState } from 'react';
import axios from 'axios';
import { useCep } from '../../../../../../hooks/cep';
import { useFormIntegrator } from '../../../../../../hooks/formIntegrator';
import config from '../../../../../../services/auth';



const { Panel } = Collapse
const { TextArea } = Input



const useStyles = makeStyles((theme) => ({
    panel: {
        borderRadius: '2px',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
    radio: {
        display: 'block',
        lineHeight: '2.4em',
        letterSpacing: '0.5px',
    },
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));
interface LabelI {
    text: string;
}

interface ActivityI { 
    code:string;
    principal:string;
    description:string;
  }
const Label = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#333333',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};



const Matriz: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { protocol } = useProtocol();
    const { cep, setCep } = useCep();
    const { 
        setDeclaration,
        setNominal_value_shares,
        setPaidin_capital,
        setCompanyEmail,
        nominal_value_shares,
        paidin_capital,
        declaration_signing_date,
        company_email
    } = useFormIntegrator();
    const [ fantasy, setFantasy ] = useState('');
    const [ socialCapital, setSocialCapital ] = useState(0);
    const [ type, setType ] = useState('');
    const [ street, setStreet ] = useState('');
    const [ numHouse, setNumHouse ] = useState();
    const [ comp, setComp ] = useState('');
    const [ neighborhood, setNeighborhood ] = useState('');
    const [ uf, setUf ] = useState('');
    const [ city, setCity ] = useState('');
    const [ social, setSocial ] = useState('');
    const [ activity, setActivity ] = useState('');
    const [ activityDesc, setActivityDesc ] = useState('');
    const [ principal, setPrincipal ] = useState<boolean>(false);
    const [ port, setPort ] = useState('Micro Empresa');
    const [ nominalValue, setNominalValue ] = useState<string>('');
    const [ paidinCapital, setPaidinCapital ] = useState<string>('');
    const [cnpj, setCnpj] = useState('');
    const [nire, setNire] = useState('');

    function identifier(min: number, max: number){
    const ano = new Date();
    const anoCorrente = String(ano.getFullYear());
    let arr: any = [];
    min = Math.ceil(min);
    max = Math.floor(max);

    arr = anoCorrente.charAt(2) + anoCorrente.charAt(3);

    let protocol = arr + min + Math.floor((max - min ) * Math.random()) ;

    return protocol;
    }

    const dbeReceipt = protocol.replace(/[^0-9]/g, '');

    async function postData() {
        const data = {
            dbe_protocol: protocol,
            dbe_receipt: dbeReceipt,
            dbe_identifier: identifier(0, 9999),
            legal_nature: "2135 - EMPRESARIO",
            nominal_value_shares: nominal_value_shares,
            paidin_capital: paidin_capital,
            declaration_signing_date: declaration_signing_date,
            company_email: company_email
        };
    
        if (!data.dbe_protocol || !data.dbe_receipt || !data.company_email) {
            Modal.error({
                title: "Erro",
                content: "Dados incompletos. Por favor, verifique os campos obrigatórios indicados com um *."            });
            return;
        }
    
        api.post('/aikjunta/integrator/', data, config)
            .then(response => {
                Modal.success({
                    title: "Sucesso",
                    content: `Sucesso! Processo ${protocol} criado com sucesso.`
                });
                history.push('/integrador/novocfn/empresario');
            })
            .catch(err => {
                Modal.warning({
                    title: "Aviso",
                    content: err.response?.data?.message || err.message || "Ocorreu um erro ao enviar os dados."
                });
                history.push('/integrador/novocfn/empresario');
            });
    }

    function getNominalValue(e: any){
        var value = e; 
        value=value.replace(/\D/g,"");
        value=value.replace(/(\d{1})(\d{15})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{12})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{9})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{5})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{1,2})$/,"$1,$2");

        setNominalValue(value);
        handleGetNominalValue(nominalValue);

    }

    function handleGetNominalValue(e: any){
        let value = e.replace(/[^\d]/g,'').replace(/.{0,1}$/, '');
        // 
        setNominal_value_shares(value);
    }

    function getPaidinCapital(e: any){
        var value = e; 
        value=value.replace(/\D/g,"");
        value=value.replace(/(\d{1})(\d{15})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{12})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{9})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{5})$/,"$1.$2");
        value=value.replace(/(\d{1})(\d{1,2})$/,"$1,$2");

        setPaidinCapital(value);
        handleGetPaidinCapital(paidinCapital);
    }

    function handleGetPaidinCapital(e: any){
        let value = e.replace(/[^\d]/g,'').replace(/.{0,1}$/, '');
        setPaidin_capital(value);
    }

    async function handleData(){ //buscar os dados que necessitam ser usados nos inputs
        const res = await api.get(`/aikjunta/dbe/${protocol}`, config);
        console.log(res.data)
        setFantasy(res.data.fantasy);
        setSocialCapital(res.data.capital_value);
        setCep(res.data.viability.viability_address.postal_code);
        setType(res.data.viability.viability_address.public_place_type);
        setStreet(res.data.viability.viability_address.public_place);
        setNumHouse(res.data.viability.viability_address.num);
        setComp(res.data.viability.viability_address.complement);
        setNeighborhood(res.data.viability.viability_address.neighborhood);
        setCity(res.data.viability.viability_address.city);
        setSocial(res.data.viability.social_object);
        setPort(res.data.viability.port.name);
        setCnpj(res.data.viability.fictional_person.cnpj);
        setNire(res.data.viability.fictional_person.nire);
        
        setActivity(res.data.viability.viability_activity[0].economic_activity.cnae);
        setActivityDesc(res.data.viability.viability_activity[0].economic_activity.occupation)
        setPrincipal(res.data.viability.viability_activity[0].is_principal);
    }

    async function handleLocation(){ //chamada a api de CEP pra pegar dados de endereço
        const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        
        let federative = res.data.uf;
        let bairro = res.data.bairro;
        setUf(federative);
        setNeighborhood(bairro);
    }

   

    const CnaeTable: ActivityI[] = [ //objeto de atividade principal
        {
            code: `${activity}`,
            description: `${activityDesc}`,
            principal: principal === true ? "Sim" : "Não"
        }
    ]

    useEffect(()=> {
        handleData();

    }, [])
    
    useEffect(() => {
        
        if(cep !== ''){
            handleLocation();

        }
    }, [cep])
    
    return (
        <ContainerCenter title={'Dados da Matriz'} icon={FaArchive} buttonBack={true}  buttonAdvanced={true} onAdvanced={postData}  advancedButtonTitle="Avançar" >
            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }}>
                <Collapse defaultActiveKey={'1'} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow
                        header={<Label text={'Dados da Empresa'} />}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Nire: </strong></p>
                                <Input style={{ width: 330 }} value={nire} disabled />
                            </div>
                            <div>
                                <p><strong>CNPJ: </strong></p>
                                <Input style={{ width: 330 }} value={cnpj} disabled/>
                            </div>
                            <div>
                                <p><strong>Nire anterior: </strong></p>
                                <Input style={{ width: 330 }} disabled />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Nome Empresarial: </strong></p>
                                <Input style={{ width: 1120 }} />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Nome Fantasia: </strong></p>
                                <Input style={{ width: 800 }} value={fantasy}/>
                            </div>
                            <div>
                                <p><strong>Porte: </strong></p>
                                
                                <Input style={{width: 300}} value={port} disabled/>
                                
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Valor capital social (R$): </strong></p>
                                <Input style={{ width: 330 }} prefix="R$" value={socialCapital.toFixed(2)} disabled />
                            </div>
                            <div>
                                <p><strong>*Valor nominal de quotas (R$): </strong></p>
                                <Input 
                                    style={{ width: 330 }} 
                                    prefix="R$" 
                                    onChange={e=>getNominalValue(e.target.value)} 
                                    value={nominalValue} 
                                    type="text"
                                    // onBlur={e => convertValue(e)}
                                />
                            </div>
                            <div>
                                <p><strong>*Capital integralizado (R$): </strong></p>
                                <Input 
                                    style={{ width: 330 }} 
                                    prefix="R$" 
                                    onChange={e=>getPaidinCapital(e.target.value)}
                                    value={paidinCapital}
                                    type="text"
                                />
                                
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>*Data Assinatura da Declaração (DD/MM/YYYY): </strong></p>
                                <Input style={{ width: 330 }} type="date" onBlur={e=>setDeclaration(e.target.value)} />
                            </div>
                            <div>
                                <p><strong>*Data término sociedade (DD/MM/YYYY): </strong></p>
                                <Input style={{ width: 330 }} type="date" disabled />
                            </div>
                            <div>
                                <p><strong>Número de associados: </strong></p>
                                <Input style={{ width: 330 }} disabled />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Data início das atividades(DD/MM/YYYY): </strong></p>
                                <Input style={{ width: 350 }} type="date" />
                            </div>

                        </div>
                    </Panel>
                </Collapse>
                <Divider />
            </Grid>


            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }} >
                <Collapse defaultActiveKey={'1'} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow
                        header={<Label text={'Endereço'} />}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>CEP:</strong></p>
                                <Input style={{ width: 200 }} value={cep} disabled/>
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Tipo de Logradouro:</strong></p>
                                <Input style={{ width: 350 }} disabled value={type}/>
                                   
                            </div>
                            <div>
                                <p><strong>Logradouro: </strong></p>
                                <Input style={{ width: 735 }} disabled value={street} />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Número:</strong></p>
                                <Input style={{ width: 350 }} disabled value={numHouse}/>
                                    
                            </div>
                            <div>
                                <p><strong>Complemento: </strong></p>
                                <Input style={{ width: 350 }} disabled value={comp}/>
                            </div>
                            <div>
                                <p><strong>Bairro: </strong></p>
                                <Input style={{ width: 350 }} disabled value={neighborhood}/>
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>UF:</strong></p>
                                <Input style={{ width: 350 }} disabled value={uf} />
                            </div>
                            <div>
                                <p><strong>Município: </strong></p>
                                <Input style={{ width: 350 }} disabled value={city} />
                            </div>
                            <div>
                                <p><strong>País: </strong></p>
                                <Input style={{ width: 350 }} disabled value="Brasil" />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>(DDD):</strong></p>
                                <Input style={{ width: 100 }} disabled/>
                            </div>
                            <div>
                                <p><strong>Telefone: </strong></p>
                                <Input style={{ width: 300 }} disabled />
                            </div>
                            <div>
                                <p><strong>Site: </strong></p>
                                <Input style={{ width: 650 }} disabled />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>*E-mail da empresa: </strong></p>
                                <Input style={{ width: 1115 }} onBlur={e=>setCompanyEmail(e.target.value)} type="email" />
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                <Divider />
            </Grid>

            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }}>
                <Collapse defaultActiveKey={'1'} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow
                        header={<Label text={'Objeto Social'} />}
                    >
                        <p><strong>Objeto Social</strong></p>
                        <TextArea rows={4} value={social} disabled/>
                    </Panel>
                </Collapse>
                <Divider />
            </Grid>

            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }}>
                <Collapse defaultActiveKey={'1'} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow
                        header={<Label text={'Atividades Econômicas'} />}
                    >
                        <p><strong>Digite a Atividade: </strong></p>
                        <Input style={{ width: 350 }} disabled />

                        <Grid item xs={12}>
                            <InformationContainer
                                title={'Atividades Incluídas'}
                                columns={PrincipalActivites}
                                dataList={CnaeTable}
                            />
                        </Grid>
                    </Panel>
                </Collapse>
        
            </Grid>
        </ContainerCenter>
    );
}

export default Matriz;