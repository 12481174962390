import React, { useState } from 'react';
import {
    Grid,
    makeStyles,
} from '@material-ui/core';
import { Collapse, Select, Form, Divider, Input, Button, Modal } from 'antd';
import { FaAddressBook } from 'react-icons/fa';
import { LabelInput } from '../../../../components';
import { ContainerCenter } from '../../../components/ContainerCenter';
import { FormEvents } from '../../../viability/pages/SubscribeFlow/SelectEvent/components/components.styled';
import TransferList from './components/TransferList';
import { useHistory } from 'react-router-dom';
import ModalMessage from './components/Modal';
import { useProtocol } from '../../../../hooks/protocol';
import api from '../../../../services/api';
import config from '../../../../services/auth';
import { useFormIntegrator } from '../../../../hooks/formIntegrator';
import axios from 'axios';


const { Panel } = Collapse
const { Option } = Select

const useStyles = makeStyles((theme) => ({
    panel: {
        borderRadius: '2px',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
    radio: {
        display: 'block',
        lineHeight: '2.4em',
        letterSpacing: '0.5px',
    },
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

interface LabelI {
    text: string;
}
const Label = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#333333',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};



const NewFCN: React.FC = () => {
    const classes  = useStyles();
    const history  = useHistory();
    const { protocol, setProtocol } = useProtocol();
    const [ vProtocol, setVProtocol ] = useState('');
    const {events ,setEvents} = useFormIntegrator();
    const [act, setAct] = useState({value:0, text:''});
    const [integrator, setIntegrator] = useState(true);

    function handleNext(){
        setEvents({...events, act: act.text})
        ModalMessage(`Sucesso! Processo ${protocol} criado com sucesso.`);
        history.push('/integrador/novocfn/matriz');
    }

    async function handleProtocol(){ //buscar protocolo de viabilidade
        try{
            const res = await api.get(`/aikjunta/dbe/${protocol}`, config);
            
            setVProtocol(res.data.viability.viability_protocol);
        }catch(err){
            Modal.error({
                content: "Protocolo não encontrado!"
            })
        }
    }

    // Tive que colocar a mensagem de sucesso dentro de um bloco de captura de exceções devido à API 
    // quebrar durante a pesquisa. Isso foge dos padrões RESTful, mas foi necessário improvisar para que 
    // o fluxo da aplicação continuasse funcionando corretamente.

    async function getIntegrator() {
        api.get(`/aikjunta/integrator/${protocol}`, config)
            .then(response => {
                Modal.warning({
                    title: "Aviso",
                    content: "Sua empresa já está integrada. Por favor, prossiga com o fluxo de registro digital.",
                });
            })
            .catch(err => {
                Modal.success({
                    title: "Sucesso",
                    content: "Sucesso! Os dados foram integrados com sucesso."
                });
                setIntegrator(false);
            });
    }
  
  
    function handleSelect(value: any, options:any){
        setAct({...act, value, text: options.children})
    }

    return (
        <ContainerCenter title={'FCN/REMP'} icon={FaAddressBook} >
            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }}>
                <Collapse activeKey={'1'} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow={false}
                        header={<Label text={'Natureza Jurídica:'} />}
                    >
                        <FormEvents layout={'horizontal'}>
                            <Form.Item style={{ padding: 4, margin: 4 }}>
                                <Label text={'Natureza Jurídica'} /> <br />
                                <Select defaultValue="" style={{ width: 400 }}>
                                    <Option value={2135}>
                                        2135 - Empresário
                                    </Option>
                                </Select> <br /> <br /> <br />
                                <Label text={'Ato'} /> <br />
                                <Select defaultValue={0} value={act.value} style={{ width: 400 }} onChange={(value, options) => handleSelect(value, options)}>
                                    <Option value={0}> </Option>
                                    <Option value={80}>
                                        080 - Inscrição
                                    </Option>
                                </Select>
                            </Form.Item>
                        </FormEvents>
                    </Panel>
                </Collapse>
                <Divider />
            </Grid>

            <Grid item xs={12} style={{ width: 1150, marginLeft: 30 }}>
                <Collapse defaultActiveKey={['1']} style={{ width: '100%' }}>
                    <Panel
                        className={classes.panel}
                        style={{ borderTop: '4px solid #28b3d6', borderRadius: 5 }}
                        key={'1'}
                        showArrow={true}
                        header={<Label text={'Eventos JUCEC:'} />}
                    >
                        <TransferList />
                    </Panel>
                </Collapse>
                <Divider />
            </Grid>

            <Grid item xs={12} style={{width: 1150, marginLeft: 30}}>
                <Collapse defaultActiveKey={['1']} style={{width: '100%'}}>
                    <Panel
                        className={classes.panel}
                        style={{borderTop: '4px solid #28b3d6', borderRadius: 5}}
                        key={'1'}
                        showArrow={true}
                        header={<Label  text={'Dados DBE/ Viabilidade'}/>}
                    >
                    
                    <p style={{fontWeight: 'bold'}}>Protocolo DBE/Redesim</p>
                    <Input style={{width: 200}} onChange={e=>setProtocol(e.target.value)}/> &emsp;
                    <Button style={{backgroundColor: '#2595df', color: '#fff'}} onClick={handleProtocol}>Buscar</Button> <br/> <br/>

                    <div style={{display: 'flex', justifyContent: 'space-between'}} >             
                        <div style={{display: 'flex', flexDirection: 'column', fontWeight: 'bold'}}>
                            <p>Viabilidade</p> 
                            <Input style={{width: 200}} value={vProtocol}/>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', fontWeight: 'bold'}}>
                            <p>DBE - Recibo</p> 
                            <Input  style={{width: 200}}/>
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', fontWeight: 'bold'}}>
                            <p>DBE - Identificador</p> 
                            <Input  style={{width: 200}}/>
                        </div>
                    </div>
                    <br/>
                    <Button style={{backgroundColor: '#2595df', color: '#fff'}}  onClick={getIntegrator} >Integrar</Button>
                    </Panel>
                </Collapse>
            </Grid>

            <Button onClick={handleNext} disabled={!vProtocol || integrator} type='primary' size='large' style={{margin: '10px 0px 10px 1090px'}}>Avançar</Button>
            

        </ContainerCenter>
    );
}

export default NewFCN;