import React from 'react';
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import {
  Container as ContainerMaterial,
  ContainerProps,
  Paper,
  Box,
} from '@material-ui/core';
import { Alert, Divider, Button } from 'antd';

import { useFlow } from '../../hooks/flow';
import { useHistory } from 'react-router-dom';
import { useFormViability } from '../../hooks/formViability';

export const Container = styled(ContainerMaterial)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface ContainerCenterProps extends ContainerProps {
  title: string;
  icon: React.FunctionComponent<any>;
  buttonBack?: boolean;
  buttonNext?: boolean;
  buttonFinish?: boolean;
  buttonAdvanced?: boolean;
  message?: string | React.ReactNode;
  onAdvanced?: () => Promise<void>;
  advancedButtonTitle?: string;
  handleNext?: () => void;
}

const ContainerCenter = ({
  children,
  title,
  icon: Icon,
  message,
  buttonNext = false,
  buttonBack = false,
  buttonFinish = false,
  handleNext,
  buttonAdvanced = false,
  onAdvanced,
  advancedButtonTitle = "Avançado",
  ...rest
}: ContainerCenterProps) => {
  const { nextLink } = useFlow();
  const { finishForm } = useFormViability();
  const history = useHistory();

  function previousNavigate() {
    history.goBack();
  }

  function nextNavigate() {
    history.push(nextLink);
  }

  return (
    <main style={{ display: 'flex', width: '100%', maxWidth: '100%' }}>
      <Container {...rest}>
        <Paper
          style={{
            maxWidth: '100%',
            width: '100%',
          }}
        >
          <Box pl={3} paddingTop={3} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'center'}>
              <Icon
                style={{ fontSize: 24, marginRight: '0.5em', color: '#9a9aa0' }}
              />
              <h2 style={{ fontSize: 24, margin: 0 }}>{title}</h2>
            </Box>
            <Divider />
            {message && (
              <Alert
                style={{ marginRight: 24, fontSize: 15 }}
                message={message}
                type={'info'}
                showIcon
              ></Alert>
            )}
          </Box>
          <Box>{children}</Box>
          {(buttonBack || buttonNext || buttonAdvanced) && (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              mt={5}
              mr={6}
              ml={3}
              mb={4}
            >
              {buttonBack && (
                <Button
                  onClick={previousNavigate}
                  size={'large'}
                  type="primary"
                  style={{
                    backgroundColor: 'white',
                    color: '#666',
                    fontWeight: 600,
                    border: '1px solid #666',
                  }}
                  icon={<ArrowLeftOutlined style={{ color: 'black' }} />}
                >
                  Voltar
                </Button>
              )}
              {buttonNext && !buttonFinish && (
                <Button
                onClick={handleNext || nextNavigate}
                  size={'large'}
                  type="primary"
                  icon={<ArrowRightOutlined />}
                >
                  Avançar
                </Button>
              )}
              {buttonAdvanced && (
                <Button
                  onClick={onAdvanced}
                  size={'large'}
                  type="primary"
                  icon={<ArrowRightOutlined />}
                >
                  {advancedButtonTitle}
                </Button>
              )}
              {buttonFinish && (
                <Button
                  onClick={finishForm}
                  size={'large'}
                  style={{
                    backgroundColor: '#27ae60',
                    color: '#fff',
                    borderColor: '#229955',
                  }}
                  icon={<CheckOutlined />}
                >
                  Concluir
                </Button>
              )}
            </Box>
          )}
        </Paper>
      </Container>
    </main>
  );
};

export { ContainerCenter };