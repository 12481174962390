import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FaUserAlt } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import { Collapse, Divider, Input, Radio, Select, Button, Modal } from 'antd';
import { LabelInput } from '../../../../../../components';
import { useHistory } from 'react-router-dom';
import api from '../../../../../../services/api';
import { useProtocol } from '../../../../../../hooks/protocol';
import { useCep } from '../../../../../../hooks/cep';
import config from '../../../../../../services/auth';
import { useFormIntegrator } from '../../../../../../hooks/formIntegrator';


const { Panel } = Collapse;
const { Option } = Select;
const useStyles = makeStyles(() => ({
    panel: {
        borderRadius: '2px',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
}));
interface LabelI {
    text: string;
}
const Label = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#333333',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};

const Empresario: React.FC = () => {
    const classes= useStyles();
    const { protocol } = useProtocol();
    const { cep, setCep } = useCep();
    const [name, setName] = useState('');
    const [ birth, setBirth] = useState<Date>(new Date()); 
    const [ cpf, setCpf ] = useState();
    const [ sex, setSex ] = useState();
    const [ rg, setRg ] = useState();
    const [ state, setState ] = useState();
    const [ father, setFather ] = useState();
    const [ mother, setMother ] = useState();
    const [ street, setStreet ] = useState();
    const [ house, setHouse ] = useState();
    const [ neighborhood, setNeighborhood ] = useState();
    const [ city, setCity ] = useState();
    const [ civil, setCivil ] = useState('');
    const { 
        setCompanyEmail, 
        setIssuing_agency 
    } = useFormIntegrator();



    const history = useHistory();

    function handleNext() {
        Modal.success({
            content: (
                <div>
                    <h1>Sucesso!</h1>
                    <p>Registro atualizado com sucesso.</p>
                    <p>Remp finalizado com sucesso. Protocolo Redesim: {protocol}</p>
                </div>
            )
        })
        history.push('/integrador/novocfn/docs');
    }
    
    async function handleData(){ //dados do empresário
        const res = await api.get(`/aikjunta/dbe/${protocol}`, config);
        
        setName(res.data.viability.fictional_person.full_name);
        setCpf(res.data.viability.fictional_person.cpf);
        setBirth(res.data.viability.fictional_person.birth_date);
        setSex(res.data.viability.fictional_person.sex);
        setRg(res.data.viability.fictional_person.rg);
        setState(res.data.viability.fictional_person.state);
        setFather(res.data.viability.fictional_person.father_name);
        setMother(res.data.viability.fictional_person.mother_name);
        setCep(res.data.viability.fictional_person.zip_code);
        setStreet(res.data.viability.fictional_person.public_place);
        setHouse(res.data.viability.fictional_person.house_number);
        setNeighborhood(res.data.viability.fictional_person.neighborhood);
        setCity(res.data.viability.fictional_person.city);
        setCivil(res.data.viability.fictional_person.marital_status);

    }

    useEffect(()=> {
        handleData();
    })
  return(
      <ContainerCenter title={'Dados do Empresário'} icon={FaUserAlt} buttonBack buttonNext={true} handleNext={handleNext}>
        <Grid item xs={12} style={{width: 1150, marginLeft: 30}}>
            <Collapse defaultActiveKey={'1'} style={{width: '100%'}}>
                <Panel 
                    className={classes.panel}
                    style={{borderTop: '4px solid #28b3d6', borderRadius: 5}}
                    key={'1'}
                    showArrow
                    header={<Label text={'Dados da Empresa'}/>}
                    >
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <p><strong>Nome: </strong></p>
                            <Input style={{width: 750}} value={name} />
                        </div>
                        <div>
                            <p><strong>CPF:</strong></p>
                            <Input style={{width: 300}} value={cpf} />
                        </div>
                    </div> <br/>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <p><strong>Data Nascimento(DD/MM/YYYY): </strong></p>
                            <Input style={{width: 300}} value={new Date(birth).toLocaleDateString()} />
                        </div>
                        <div>
                            <p><strong>Sexo:</strong></p>
                            <Radio.Group value={sex === "masculino" ? 1 : 2}>
                                <Radio value={1} >Masculino</Radio>
                                <Radio value={2}>Feminino</Radio>
                            </Radio.Group>
                        </div>
                        <div>
                            <p><strong>Estado Civil</strong></p>
                            <Input style={{width: 300}} value={civil.toLocaleUpperCase('pt-BR')} disabled />
                        </div>
                    </div> <br/>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <p><strong>Regime de Bens: </strong></p>
                            <Select style={{width: 300}}>
                                <Option value='a'>selecione</Option>
                            </Select>
                        </div>
                        <div>
                            <p><strong>Nacionalidade: </strong></p>
                            <Select style={{width: 300}} defaultValue="a">
                                <Option value='a'>Brasileira</Option>
                            </Select>
                        </div>
                        <div>
                            <p><strong>Emancipação: </strong></p>
                            <Select style={{width: 300}}>
                                <Option value='a'>selecione</Option>
                            </Select>
                        </div>
                    </div> <br/>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <p><strong>Identidade: </strong></p>
                            <Input style={{width: 300}} value={rg}/>
                        </div>
                        <div>
                            <p><strong>UF: </strong></p>
                            <Input style={{width: 300}} value={state} />
                        </div>
                        <div>
                            <p><strong>Órgão Emissor: </strong></p>
                            <Input style={{width: 300}} onChange={e => setIssuing_agency(e.target.value)} />
                        </div>
                    </div> <br/>

                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <p><strong>Nome do Pai: </strong></p>
                            <Input style={{width: 300}} value={father}/>
                        </div>
                        <div>
                            <p><strong>Nome da Mãe: </strong></p>
                            <Input style={{width: 300}} value={mother} />
                        </div>
                        <div>
                            <p><strong>Endereço Eletrônico do Empresário: </strong></p>
                            <Input style={{width: 300}} onChange={e => setCompanyEmail(e.target.value)}/>
                        </div>
                    </div> <br/>
                </Panel>
            </Collapse>
        <Divider/>
        </Grid>

        <Grid item xs={12} style={{width: 1150, marginLeft: 30}}>
            <Collapse defaultActiveKey={'1'} style={{width: '100%'}}>
                <Panel
                    className={classes.panel}
                    style={{borderTop: '4px solid #28b3d6', borderRadius: 5}}
                    key={'1'}
                    showArrow
                    header={<Label text={'Endereço'}/>}
                >
                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>CEP:</strong></p>
                                <Input style={{ width: 200 }} value={cep} disabled />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Tipo de Logradouro:</strong></p>
                                <Select style={{ width: 350 }} disabled>
                                    <Option value="quadra">Quadra</Option>
                                </Select>
                            </div>
                            <div>
                                <p><strong>Logradouro: </strong></p>
                                <Input style={{ width: 735 }} disabled value={street}/>
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>Número:</strong></p>
                                <Input style={{width: 350}} value={house} disabled />
                            </div>
                            <div>
                                <p><strong>Complemento: </strong></p>
                                <Input style={{ width: 350 }} disabled />
                            </div>
                            <div>
                                <p><strong>Bairro: </strong></p>
                                <Input style={{ width: 350 }} disabled value={neighborhood} />
                            </div>
                        </div> <br />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <p><strong>UF:</strong></p>
                                <Input style={{ width: 350 }} disabled value={state} />
                            </div>
                            <div>
                                <p><strong>Município: </strong></p>
                                <Input style={{ width: 350 }} disabled value={city} />
                            </div>
                            <div>
                                <p><strong>País: </strong></p>
                                <Input style={{ width: 350 }} disabled value="Brasil" />
                            </div>
                        </div> <br />
                </Panel>
            </Collapse>
        </Grid>
      </ContainerCenter>
  );
}

export default Empresario;